import React, { Fragment } from "react";
import Joi from "joi-browser";
import Form from "../common/forms/form";
import auth from "../services/authService";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../assets/images/bcompliant_logo.png";



class LoginAs extends Form {
    state = {
        data: { email: "", password: "" },
        errors: {},
        loading: false
    };

    schema = {
        email: Joi.string().max(50).required().email().label("Email"),
        password: Joi.string().max(50).required().label("Password"),
    };

    doSubmit = async () => {
        try {
            this.setState({ loading: 1 });
            await auth.loginWithJwt(this.props.match.params.token);
            toast.success("Logged in Successfully! Redirecting...")

            const queryParams = new URLSearchParams(this.props.location.search);
            const redirectTo = queryParams.get("redirectTo");
            if (redirectTo) {
                window.location = redirectTo;
            } else {
                // check if user got redirected from somewhere
                const { state } = this.props.location;
                window.location = state ? state.from.pathname : "/";
            }

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                errors.formError = ex.response.data;
                this.setState({ errors });
            }
            this.setState({ loading: 0 });
        }
    };

    componentDidMount() {
        var referrer = document.referrer;

        const validReferrers = [
            // local react and admin
            'http://localhost:8082',
            'http://localhost:3000',
            // test react and admin
            'http://test-public.bcompliant.com:8082',
            'https://test.bcompliant.com',
            // prod react and admin
            'https://admin.bcompliant.com',
            'https://account.bcompliant.com',
            // beta react and admin
            'http://www-beta.bcompliant.com:8082',
            'https://beta.bcompliant.com',
        ]

        if (validReferrers.some(el => referrer.includes(el))) {
            this.doSubmit();
        } else {
            toast.error("Error: please close windows and try again from admin")
        }
    }

    render() {
        return (
            <Fragment>
                <ToastContainer />
                <div className="page-wrapper">
                    <div className="auth-bg">
                        <div className="authentication-box">
                            <div className="text-center"><img src={logo} className="image-logo" alt="" /></div>
                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h4>Login In....</h4>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        );
    }
}

export default LoginAs;
