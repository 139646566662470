import React, { Fragment, useState, useMemo } from 'react';
import { Col, Row, Tabs } from 'antd';

import Compliance from './Compliance';
import Violation from './OpenViolation';
import ViolationPhilly from './ViolationPhilly';

import Complaints from './Complaints';
import ActivitySnapshot from './ActivitySnapshot/index';
import Top10 from './Top10/index';
import Colors from './Colors';
import HistoryUpdates from '../buildings/partials/historyUpdates';
import SmallCalendar from '../calendar/smallCalendar';
import ModalSnapshot from './ModalSnapshot'
import TODO from './todoList'
import { getCurrentUser } from "../../services/authService";

const Dashboard = (props) => {
  const locationEnv = useMemo(() => getCurrentUser()?.locationEnv, []);
  const { TabPane } = Tabs;
  const [selectedDate, setSelectedDate] = useState(null)


  const [modalSub, setModalSub] = useState(null)
  const [modalCategory, setModalCategory] = useState(null)

  const snapshotOnClickHandler = (sub, category) => {
    setModalSub(sub)
    setModalCategory(category)
  }


  return (
    <Fragment>
      <div className="container-fluid pr-0 pl-0">
        <Row justify="center" className="row">
          <Col xl={{ span: 17, offset: 0 }}>
            <div className="pt-4 pl-4 pr-4 custom-bg">
              {locationEnv === 'NYC' ? (
                <Tabs defaultActiveKey="1" className="custom_tab_wrapper">
                  <TabPane
                    tab="OPEN VIOLATIONS"
                    key="1"
                    className={`common_custom_tab`}
                  >
                    <Violation
                      history={props.history}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      snapshotOnClickHandler={snapshotOnClickHandler}
                    />
                  </TabPane>
                  <TabPane tab="COMPLAINTS" key="2" className={`common_custom_tab`} >
                    <Complaints history={props.history} selectedDate={selectedDate} setSelectedDate={setSelectedDate} snapshotOnClickHandler={snapshotOnClickHandler} />
                  </TabPane>
                  <TabPane tab="COMPLIANCE" key="3" className={`common_custom_tab`}>
                    <Compliance history={props.history} />
                  </TabPane>

                  <TabPane tab="LABELS" key="4" className={`common_custom_tab`} >
                    <Colors history={props.history} selectedDate={selectedDate} setSelectedDate={setSelectedDate} snapshotOnClickHandler={snapshotOnClickHandler} />
                  </TabPane>

                  <TabPane tab="ACTIVITY" key="5" className={`common_custom_tab`}>
                    <ActivitySnapshot history={props.history} />
                  </TabPane>
                  <TabPane tab="TOP-10" key="6" className={`common_custom_tab`}>
                    <Top10 history={props.history} />
                  </TabPane>
                </Tabs>) :
                (
                  <Tabs defaultActiveKey="1" className="custom_tab_wrapper">
                    <TabPane
                      tab="VIOLATIONS"
                      key="1"
                      className={`common_custom_tab`}
                    >
                      <ViolationPhilly
                        history={props.history}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        snapshotOnClickHandler={snapshotOnClickHandler}
                      />
                    </TabPane>

                    <TabPane tab="LABELS" key="4" className={`common_custom_tab`} >
                      <Colors noOutstanding={true} history={props.history} selectedDate={selectedDate} setSelectedDate={setSelectedDate} snapshotOnClickHandler={snapshotOnClickHandler} />
                    </TabPane>


                  </Tabs>)
              }
            </div>
          </Col>
          <Col span={24} xl={{ span: 7, offset: 0 }}>
            <div className="sidebar recent-activity pl-3 pr-3">
              <TODO />
              <div className="pt-3 pb-3">
                {props.jestData ? null : <SmallCalendar />}
              </div>
              <HistoryUpdates />
            </div>
          </Col>

        </Row>
      </div>
      <ModalSnapshot
        selectedDate={selectedDate}
        sub={modalSub}
        category={modalCategory}
      />
    </Fragment>
  );
};

export default Dashboard;
