import ChatRooms from "../common/chatRoom/rooms"
import React, { Fragment, useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import creApi from "../services/creService";
import ReactLoading from "react-loading";
import { SocketContext } from '../../context/socket';
import { Link } from "react-router-dom";
import { permissionsKey } from "../services/authService";
import Guard from "../common/guard";


const RightSidebar = ({ setDotVisible }) => {

    const socket = useContext(SocketContext);
    const [filter, setFilter] = useState('');
    const [chatData, setChatData] = useState([]);
    const [workOrderChatData, setWorkOrderChatData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userPermissions, setUserPermissions] = useState(JSON.parse(localStorage.getItem(permissionsKey))?.workOrders);

    const getChatList = async () => {
        setLoading(true);
        try {
            const chatList = await creApi.getChatList();
            setChatData(chatList.data.chats);
            const countUnread = chatList.data.chats.reduce((res, el) => res + el.unreadMessagesCount, 0)
            if (countUnread) {
                setDotVisible((value) => value + countUnread)
            }
            setLoading(false);
        } catch (e) {
            toast.error(e.message)
        }
    }

    const getWorkOrderChatList = async () => {
        setLoading(true);
        try {
            const chatList = await creApi.getWorkOrderChatList();
            if (chatList.data.status) {
                if (chatList.data.permissions) {
                    localStorage.setItem(permissionsKey, JSON.stringify(chatList.data.permissions));
                    setUserPermissions(chatList.data.permissions['workOrders']);
                }
                setWorkOrderChatData(chatList.data.chats);
                const countUnread = chatList.data.chats.reduce((res, el) => res + el.unreadMessagesCount, 0)
                if (countUnread) {
                    setDotVisible((value) => value + countUnread)
                }
            }
        } catch (e) {
            toast.error(e.message)
        }
        setLoading(false);
    }
    const updateBadge = (value) => {
        setDotVisible((count) =>
            value.unreadMessagesCount &&
                (count - value.unreadMessagesCount) < 0 ? 0 : (count - value.unreadMessagesCount)
        )
        setWorkOrderChatData(workOrderChatData.map(el => {
            if (el._id === value._id) {
                el.unreadMessagesCount = 0
            }
            return el
        }))
        setChatData(chatData.map(el => {
            if (el._id === value._id) {
                el.unreadMessagesCount = 0
            }
            return el
        }))

    }

    useEffect(() => {
        getChatList();
        getWorkOrderChatList();

        const handler = (message) => {
            setChatData(message.chats);
            setDotVisible((value) => value + 1)
        };

        const woChathandler = (message) => {
            setWorkOrderChatData(message.chats);
            setDotVisible((value) => value + 1)
        };
        if (socket) {
            socket.on('chat-message', handler);
            socket.on('work-order-chat-message', woChathandler);
            return () => socket.off('chat-message', handler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="right-sidebar" id="right_side_bar" style={{ maxWidth: '100%' }}>
                <div className="container p-0">
                    <div className="modal-header p-l-20 p-r-20">
                        <div className="col-sm-8 p-0">
                            <h6 className="modal-title ">Company Chats</h6>
                        </div>

                    </div>
                </div>
                <div className="friend-list-search mt-0 pl-2 pr-2">
                    <input type="text" placeholder="search" onChange={(e) => setFilter(e.target.value)} />
                    <i className="fa fa-search"></i></div>
                <ChatRooms filter={filter} setDotVisible={setDotVisible} />
                <div className="d-flex w-100">
                    <div className="w-100">
                        {loading ? (<ReactLoading type={"cylon"} color={"grey"} />) :
                            (<div className="card">
                                {chatData.length > 0 &&
                                    <Guard
                                        serviceName="violations"
                                        action="all"
                                        allow={() => (
                                            <>
                                                <div className="modal-header p-l-20 p-r-20">
                                                    <div className="col-sm-8 p-0">
                                                        <h6 className="modal-title ">Violation Chats</h6>
                                                    </div>

                                                </div>

                                                <div>
                                                    <div className="card">
                                                        <ul className="list-group list-group-flush" style={{ maxHeight: '214px', overflow: 'auto' }}>
                                                            {
                                                                chatData && chatData.filter(value => {
                                                                    return (
                                                                        value.violation.category +
                                                                        value.violation.sub +
                                                                        value.uniqueData.value +
                                                                        value.uniqueData.displayName +
                                                                        value.building.display_address +
                                                                        value.building.address
                                                                    ).includes(filter)
                                                                })
                                                                    .map((value, key) => {
                                                                        return <li key={key} className="list-group-item chat-div">
                                                                            <Link
                                                                                onClick={() => updateBadge(value)}
                                                                                className="d-flex justify-content-between text-dark text-decoration-none pointer"
                                                                                to={`/${!value.violation.isCustomCompliance ? 'violation-details' : 'custom-compliance/' + value.violation.sub}/${value.violation._id}/chats#chatMessage`}  >
                                                                                <div>
                                                                                    <span className="badge badge-dark ">{value.uniqueData.displayName}: {value.uniqueData.value}</span>
                                                                                    <div>
                                                                                        {value.violation.category.replace(/_/g, ' ').replace(/__/g, ' ')}/
                                                                                        {value.violation.sub.replace(/_/g, ' ').replace(/__/g, ' ')}
                                                                                    </div>

                                                                                    <small className="d-block">
                                                                                        {value.building.display_address ? value.building.display_address : value.building.address}
                                                                                    </small>
                                                                                    <span className="badge badge-info">
                                                                                        {value.messages.length} {value.messages.length === 1 ? 'Message' : 'Messages'}
                                                                                    </span>
                                                                                    {(value.unreadMessagesCount) > 0 &&
                                                                                        <span className="badge badge-danger ">{value.unreadMessagesCount} New</span>
                                                                                    }
                                                                                </div>

                                                                            </Link>
                                                                        </li>
                                                                    })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    />
                                }
                                {(!userPermissions || userPermissions.includes('view')) &&
                                    workOrderChatData.length > 0 &&
                                    <>
                                        <div className="modal-header p-l-20 p-r-20">
                                            <div className="col-sm-8 p-0">
                                                <h6 className="modal-title ">Work Order Chats</h6>
                                            </div>

                                        </div>

                                        <div >
                                            <div className="card">
                                                <ul className="list-group list-group-flush" style={{ maxHeight: '214px', overflow: 'auto' }}>
                                                    {loading ? (<ReactLoading type={"cylon"} color={"grey"} />) : (
                                                        workOrderChatData.filter(value => {
                                                            return (
                                                                value.workOrder.workOrderId +
                                                                value.workOrder.category +
                                                                value.building.display_address +
                                                                value.building.address
                                                            ).includes(filter)
                                                        }).map((value, key) => {
                                                            return <li key={key} className="list-group-item chat-div">
                                                                <Link
                                                                    onClick={() => updateBadge(value)}
                                                                    className="d-flex justify-content-between text-dark text-decoration-none pointer"
                                                                    to={`/work-orders/view/${value.workOrder._id}/chats`}
                                                                >
                                                                    <div>
                                                                        <span className="badge badge-dark ">#{value.workOrder.workOrderId}</span>
                                                                        <div> {value.workOrder.category} </div>
                                                                        <small className="d-block">
                                                                            {value.building.display_address ? value.building.display_address : value.building.address}
                                                                        </small>

                                                                        <span className="badge badge-info">
                                                                            {value.messages.length} {value.messages.length === 1 ? 'Message' : 'Messages'}
                                                                        </span>
                                                                        {(value.unreadMessagesCount) > 0 &&
                                                                            <span className="badge badge-danger">{value.unreadMessagesCount} New</span>
                                                                        }
                                                                    </div>

                                                                </Link>
                                                            </li>
                                                        })
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                }
                                {chatData.length <= 0 && (workOrderChatData.length <= 0 || (userPermissions && !userPermissions?.includes('view'))) && !loading && <div className="alert alert-info mt-3">No Chats</div>}
                            </div>)}
                    </div>

                </div>
                <div style={{ height: '81px' }}></div>

            </div>


        </div>
    )
}

export default RightSidebar; 