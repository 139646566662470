import React, { useContext, useEffect, useState } from 'react';
import AppWrapper from './Wrapper';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './common/loader';
import auth from '../components/services/authService';
import { SocketContext } from '../context/socket';

const AppLayout = ({
  children,
  history,
  hideSidebar,
  hideHeader,
  hideFooter,
}) => {
  const socket = useContext(SocketContext);
  sessionStorage.setItem('lastPath', window.location);
  const [dotVisible, setDotVisible] = useState(0);
  useEffect(() => {
    const user = auth.getCurrentUser();
    if (socket) {
      socket.on('connect', () => {
        console.log('connecting again to socket');
        socket.emit('join', user);
      });

      socket.on('connect_error', (err) => handleSocketErrors(err));
      socket.on('connect_failed', (err) => handleSocketErrors(err));
      socket.on('disconnect', (err) => handleSocketErrors(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const handleSocketErrors = (err) => {
    console.log('Error in connecting with Socket', err.message);
    socket.disconnect();
  };

  return (
    <AppWrapper>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          {!hideHeader && <Header history={history} dotVisible={dotVisible} />}
          {!hideSidebar && <Sidebar socket={socket} />}

          <RightSidebar setDotVisible={setDotVisible} />
          <div className={!hideHeader ? 'page-body' : 'bg-white'}>
            {children}
          </div>
          {!hideFooter && <Footer />}
        </div>
      </div>
      <ToastContainer />
    </AppWrapper>
  );
};

export default AppLayout;
